<template>
  <v-container fluid class="ma-0 pa-0" :class="{'light-background': $vuetify.breakpoint.xsOnly, 'white': $vuetify.breakpoint.smAndUp}">
    <v-layout row wrap>
      <v-flex xs12 max-width-1400 full-width ma-auto pa-3>
        <h3 class="graphik-bold font-20 primary--text mb-3">{{ $ml.get('profile_policies') }}</h3>

        <v-card flat height="100%" class="my-3" :class="{'light-background': $vuetify.breakpoint.smAndUp, 'white': $vuetify.breakpoint.xsOnly}" v-for="(policy, index) in policies" :key="index">
          <v-card-text>
            <v-layout row wrap align-center class="min-height-150">
              <v-flex xs12 md3 d-flex align-center>
                <v-img
                  :aspect-ratio="16/9"
                  contain
                  :src="getImage(policy.company_internal_name)"
                  :lazy-src="getImage(policy.company_internal_name)"
                  height="150px"
                  max-width="150px"
                  width="100%"
                >
                  <v-layout
                    slot="placeholder"
                    fill-height
                    align-center
                    justify-center
                    ma-0
                  >
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </v-layout>
                </v-img>

                <v-btn 
                  v-if="$vuetify.breakpoint.smAndDown"
                  color="primary"
                  depressed
                  @click="$goTo('/poliza/' + policy.uuid, 'goto_view_policy')"
                  class="normalcase graphik-medium ma-0 border-radius-5 white--text px-5"
                >{{ $ml.get('general_view_policy') }}</v-btn>
              </v-flex>

              <v-flex xs12 md6>
                <p class="graphik-medium dark-grey--text font-15 mb-0">{{ $ml.get('fields_insurance') }}: <span class="graphik-light"> {{ policy.policy_type }} </span></p>
                <p class="graphik-medium dark-grey--text font-15 mb-0">{{ $ml.get('fields_coverage') }}: <span class="graphik-light"> {{ policy.product_name }} </span></p>
                <p class="graphik-medium dark-grey--text font-15 mb-0">{{ $ml.get('fields_inssured_good') }}: <span class="graphik-light"> {{ policy.car }} </span></p>
              </v-flex>

              <v-flex xs12 md3 text-xs-center v-if="$vuetify.breakpoint.mdAndUp">
                <v-btn
                  v-if="policy.status === 0"
                  color="primary"
                  depressed block
                  @click="$goTo('/emitir/' + policy.type + '/' + policy.quote_uuid + '/' + policy.product_uuid + '/' + policy.frequency + '/' + policy.uuid, 'goto_continue_issuance')"
                  class="normalcase graphik-medium mx-0 mb-3 border-radius-5 white--text"
                >{{ $ml.get('general_continue_issuance') }}</v-btn>

                <v-btn
                  v-else-if="policy.status === 1" 
                  color="primary"
                  depressed block
                  @click="payPolicy(policy)"
                  class="normalcase graphik-medium mx-0 mb-3 border-radius-5 white--text"
                >{{ $ml.get('general_pay') }}</v-btn>

                <v-btn
                  v-else-if="policy.status === 2"
                  color="primary"
                  depressed block
                  @click="$goTo('/poliza/' + policy.uuid, 'goto_view_policy')"
                  class="normalcase graphik-medium mx-0 mb-3 border-radius-5 white--text"
                >{{ $ml.get('general_view_policy') }}</v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>

        <v-card v-if="!policies.length" flat tile :class="{'light-background': $vuetify.breakpoint.smAndUp, 'white': $vuetify.breakpoint.xsOnly}">
          <v-img
            :aspect-ratio="16/9"
            contain
            :src="image"
            :lazy-src="image"
            height="auto"
            position="bottom left"
            class="absolute"
            max-width="130px"
            width="100%"
          >
            <v-layout
              slot="placeholder"
              fill-height
              align-center
              justify-center
              ma-0
            >
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-layout>
          </v-img>

          <v-card-text>
            <v-layout row wrap align-center>
              <v-flex xs12 offset-md2 md6>
                <h3
                  class="graphik-bold secondary--text my-3 px-3"
                  :class="{'font-20': $vuetify.breakpoint.mdAndUp, 'font-12': $vuetify.breakpoint.smAndDown}"
                >{{ $ml.get('profile_policies_quote') }}</h3>
              </v-flex>

              <v-flex xs12 md4 text-xs-right>
                <v-btn 
                  color="primary"
                  depressed
                  @click="$goTo('/', 'goto_quote')"
                  class="normalcase graphik-bold-italic ma-0 px-5 border-radius-5 white--text"
                >{{ $ml.get('general_quote') }} <v-icon dark small class="ml-2">arrow_forward</v-icon></v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <message-dialog :dialog="g_dialog"></message-dialog>
    <loading-dialog :isLoading="g_isLoading"></loading-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'ProfilePolicies',
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_PROFILE_POLICIES,
    meta: [
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_PROFILE_POLICIES
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_PROFILE_POLICIES
      }
    ]
  },
  data () {
    return {
      policies: [],
      image: require('@/assets/img/images/profile_policies.png')
    }
  },
  methods: {
    getImage (company) {
      return require('@/assets/img/companies/' + company + '-full.png')
    },
    getPolicies () {
      this.g_isLoading = true

      this.$api.getPolicies(
        response => {
          this.policies = response.body.data
          this.g_isLoading = false
        }, response => this.$errorHandling(response).then(() => {
          this.getPolicies()
        }, () => {})
      )
    },
    payPolicy (policy) {
      this.$analytics('goto_pay_policy')
      window.open(policy.open_payment_url, '_self')
    }
  },
  mounted () {
    this.getPolicies()
  }
}
</script>

<style scoped>
  .absolute {
    position: absolute;
    left: 0;
    bottom: 0;
  }
</style>
